import React, { useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import Navbar from '../component/Navbar/Navbar';
import Footer from '../component/Footer';
import GetNotification from '../component/GetNotification/GetNotification';
import InstallSnackBar from '../component/InstallSnackBar';

export function Layout() {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const referer_id = searchParams.get('referer_id');
    referer_id && localStorage.setItem('referer_id', referer_id);
  });

  return (
    <>
      <Navbar />
      <InstallSnackBar />
      <Outlet />
      <Footer />
      <GetNotification />
      {/* GetNotification is pop appear as overlay and wont be problem to the UI */}
    </>
  );
}
