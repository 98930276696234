import React from 'react';
import './NotificReqPopup.css';

export default function NotificReqPopup({
  handleNotificationReq,
  handleCloseNotificationReq,
}) {
  return (
    <div className="notificationReq-modal">
      <div className="notificationReq-modal-content">
        <div className="notificationReq-modal-header">
          <h2>Notifications</h2>
        </div>
        <div className="notificationReq-modal-body">
          <p>
            Don't miss any updates! Want to permit us to send personalized
            notifications for reading Babasaheb's writings and speeches?
          </p>
        </div>
        <div className="notificationReq-modal-footer">
          <button
            onClick={handleNotificationReq}
            className="notificationReq-button"
          >
            Yes! Take me there
          </button>
          <button
            onClick={handleCloseNotificationReq}
            className="notificationReq-button"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
