import React, { useEffect } from 'react';
import Bio from './Bio';
import Banner from './Banner';
import ScrollToTopButton from '../../component/ScrollToTopButton';

import 'animate.css';
import './About.css';

function About() {
  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.slice(1);
      const target = document.getElementById(hash);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div>
      <Banner />
      <Bio />
      <ScrollToTopButton />
    </div>
  );
}

export default About;
