export const serverUrl = 'https://baws.in/';
export const pdfjsUrl =
  'https://' + window.location.hostname + '/pdfjs/web/viewer.html';
export const booksServerUrl = 'https://assets.studyring.org/assets/books';
export var searchHistory = new Map();
export const key_searchPageUrl = 'searchPageUrl';
export var key_bookUrl = 'bookUrl';
export var bookHistory = new Map();
bookHistory.set(key_bookUrl, {
  shortUrl: 'https://www.baws.in/books/baws/EN/Volume_01/pdf/37',
  longUrl:
    'https://assets.studyring.org/assets/books/baws/EN/Volume_01.pdf#page=38',
});
