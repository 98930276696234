import { signOut } from 'firebase/auth';
import { userLogout } from '../../store/user/userAction';

export const userLogoutHandler = async ({ auth, dispatch }) => {
  try {
    await signOut(auth);
    dispatch(userLogout());
    localStorage.removeItem('accessToken');
  } catch (err) {
    console.log('Error in logging out the user');
  }
};
