import React, { useEffect, useState } from 'react';
import { generateToken, isSupported } from '../../config/firebase';
import NotificReqPopup from './NotificReqPopup';

export default function GetNotification() {
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  async function checkSupport() {
    const browserSupport = isSupported();
    console.log(browserSupport, 'support hai bhai');
    if (browserSupport) {
      const lastPopupTimestamp = localStorage.getItem('lastPopupTimestamp');
      const permissionStatus = Notification.permission;
      const currentTimestamp = new Date().getTime();

      if (
        (!lastPopupTimestamp ||
          currentTimestamp - lastPopupTimestamp > 7 * 24 * 60 * 60 * 1000) &&
        permissionStatus === 'default'
      ) {
        localStorage.setItem('lastPopupTimestamp', currentTimestamp);

        const timer = setTimeout(() => {
          setShowNotificationModal(true);
        }, 5000);

        return () => clearTimeout(timer);
      }
    }
  }
  useEffect(() => {
    checkSupport();
  }, []);

  const handleNotificationReq = async () => {
    setShowNotificationModal(false);
    try {
      await generateToken();
    } catch (err) {
      console.log('Unable to generate Token');
    }
  };

  const handleCloseNotificationReq = () => {
    setShowNotificationModal(false);
  };
  return (
    showNotificationModal && (
      <NotificReqPopup
        handleNotificationReq={handleNotificationReq}
        handleCloseNotificationReq={handleCloseNotificationReq}
      />
    )
  );
}
