import {
  collection,
  where,
  query,
  getDocs,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../../config/firebase';
import { isOnMobile } from '../../util/util';
import { auth } from '../../config/firebase';

export const getUserData = async (uid) => {
  const userCollection = collection(db, 'User');
  const userQuery = query(userCollection, where('uid', '==', uid));
  try {
    const spanShot = await getDocs(userQuery);
    return spanShot.docs[0].data();
  } catch (err) {
    throw err;
  }
};

export const updateUserData = async (uid, update) => {
  const userCollection = collection(db, 'User');
  const userQuery = query(userCollection, where('uid', '==', uid));
  try {
    const spanShot = await getDocs(userQuery);
    return spanShot.docs[0].data();
  } catch (err) {
    throw err;
  }
};

export const checkUserNameTaken = async (username) => {
  const userCollection = collection(db, 'User');
  const userQuery = query(userCollection, where('username', '==', username));
  try {
    const spanShot = await getDocs(userQuery);
    return spanShot.docs;
  } catch (err) {
    throw err;
  }
};

export const getUserBookMarks = async (uid) => {
  const bookMarks = collection(db, `BookMarks/${uid}/collection`);
  const bookMarkQuery = query(bookMarks);
  try {
    const spanShot = await getDocs(bookMarkQuery);
    const bookMarks = spanShot.docs.map((item) => {
      const obj = {
        url: item.data().url,
        text: item.data().text,
        pageNo: item.data().pageNo,
        context: item.data().context,
        baseUrl: item.data().baseUrl,
        id: item.id,
        created: item.created,
        shared: false,
      };
      return obj;
    });

    return bookMarks;
  } catch (err) {
    throw err;
  }
};
export async function checkUploadBookMarks() {
  const bookMark = JSON.parse(localStorage.getItem('bookMarks'));

  if (bookMark && bookMark.length > 0) {
    if (auth.currentUser) {
      const bookMarksCollection = collection(
        db,
        `BookMarks/${auth.currentUser.uid}/collection`
      );

      bookMark.forEach(async (bm) => {
        await addDoc(bookMarksCollection, {
          uid: auth.currentUser.uid,
          url: bm.url,
          text: bm.text,
          pageNo: bm.pageNo,
          context: bm.context,
          title: bm.title,
          baseUrl: bm.baseUrl,
          created: serverTimestamp(),
          shared: false,
        });
      });
      localStorage.removeItem('bookMarks');
    } else {
      console.log('BookMark cannot be saved');
    }
  }
}

export async function checkUploadFCMToken() {
  const token = localStorage.getItem('notificationToken');

  if (token && token.length > 0) {
    if (auth.currentUser) {
      const tokenCollection = collection(
        db,
        `FCMTokens/${auth.currentUser.uid}/collection`
      );
      try {
        const docRef = await addDoc(tokenCollection, {
          uid: auth.currentUser.uid,
          token: token,
          isMobile: isOnMobile(),
          userAgent: navigator.userAgent,
          topics: 'default',
          created: serverTimestamp(),
        });
        if (!docRef) {
          console.log('Failed to store FCM token');
          return;
        }
      } catch (err) {
        console.log('error in storing FCM Tokens');
        return;
      }
      const currentTimestamp = new Date().getTime();
      localStorage.setItem('FCMtokenUploadedToServer', currentTimestamp);
    } else {
      console.log('Token cannot be saved');
    }
  }
}
