import React, { useState } from 'react';
import {
  Outlet,
  NavLink,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Col, Container, Row } from 'react-bootstrap';
// import Nav from "react-bootstrap/Nav";
import Typography from '@mui/material/Typography';
import { indigo } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AutoComplete from '../component/AutoComplete';
import {
  bookHistory,
  key_bookUrl,
  key_searchPageUrl,
  searchHistory,
} from '../util/constants';
import SignupLoginDialog from '../component/SignupDialog';
import './SearchFilters.css';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const SEARCH_TYPES = ['simple', 'vector', 'proximity'];
const defaultSearchType = (x) => (SEARCH_TYPES.includes(x) ? x : 'simple');

export default function HomeLayout() {
  const [searchParams] = useSearchParams();
  const [searchType, setSearchType] = useState(
    defaultSearchType(searchParams.get('searchType'))
  );
  const [searchText, setSearchText] = useState(searchParams.get('q') || '');
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const user = useSelector((state) => state.userReducer);

  const handleSearch = (text) => {
    const q = text.replace(/^\d+\.\s*/, '');
    // TODO: refactor to declarative
    const searchType = document.querySelector(
      'input[name="searchType"]:checked'
    ).value;
    const url = `/search?q=${q}&searchType=${searchType}`;
    searchHistory.set(key_searchPageUrl, url);
    setSearchText(q);
    navigate(url);
  };

  const setBookContextUrl = (e, vol_name, page_num) => {
    e.preventDefault();

    bookHistory.set(key_bookUrl, {
      shortUrl: e.target.href,
      longUrl: `https://assets.studyring.org/assets/books/baws/EN/${vol_name}.pdf#page=${page_num}`,
    });

    navigate(`/books/baws/EN/${vol_name}/pdf/${page_num}`);
  };

  const navLinkClassName = ({ isActive }) => `nav-link ${isActive && 'active'}`;

  return (
    <Container>
      <Row className="search__filters">
        <div>
          {matches ? (
            <Typography
              variant="h4"
              gutterBottom
              align="center"
              sx={{ fontWeight: 900 }}
              color={indigo[500]}
            >
              Dr. Babasaheb Ambedkar Writings and Speeches
            </Typography>
          ) : (
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              sx={{ fontWeight: 900 }}
              color={indigo[500]}
            >
              Dr. Babasaheb Ambedkar Writings and Speeches
            </Typography>
          )}

          <Typography variant="body2" gutterBottom align="center">
            Ambedkar International Center, USA
          </Typography>
        </div>
      </Row>
      <Row>
        <Col>
          {/* <h3 className="search__title"> Search Type </h3> */}
          <div>
            <RadioGroup
              row
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
            >
              <FormControlLabel
                value="simple"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: { xs: '12px', sm: '15px', md: '16px' },
                      },
                    }}
                  />
                }
                label="Simple"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: { xs: '12px', sm: '15px', md: '16px' },
                    fontFamily: 'Montserrat',
                  },
                }}
                name="searchType"
              />
              <FormControlLabel
                value="proximity"
                control={
                  <Radio
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: { xs: '12px', sm: '15px', md: '16px' },
                      },
                    }}
                  />
                }
                label="Advanced"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontSize: { xs: '12px', sm: '15px', md: '16px' },
                    fontFamily: 'Montserrat',
                  },
                }}
                name="searchType"
              />
              {user.isBetaTester && (
                <FormControlLabel
                  value="vector"
                  control={
                    <Radio
                      sx={{
                        '& .MuiSvgIcon-root': {
                          fontSize: { xs: '12px', sm: '15px', md: '16px' },
                        },
                      }}
                    />
                  }
                  label="Experimental"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: { xs: '12px', sm: '15px', md: '16px' },
                      fontFamily: 'Montserrat',
                    },
                  }}
                  name="searchType"
                />
              )}
            </RadioGroup>
          </div>
        </Col>
      </Row>

      <Row className="search__box">
        <Col>
          <AutoComplete
            defaultValue={searchText}
            onSelect={handleSearch}
            onSetBookUrl={setBookContextUrl}
          />
        </Col>
      </Row>

      {/* {location.pathname === "/search" || (
        <div>
          <QuoteOfTheDay />
        </div>
      )} */}
      <div className="navbar-wrapper">
        <nav variant="tabs" className="mb-3 nav--div">
          <NavLink
            to={`search?q=${searchText}&searchType=${searchType}`}
            className={navLinkClassName}
          >
            Search
          </NavLink>

          <NavLink to="." className={navLinkClassName}>
            Catalogue
          </NavLink>

          <NavLink to="bookmarks" className={navLinkClassName}>
            Bookmarks
          </NavLink>
          <NavLink to="swaraj-critique" className={navLinkClassName}>
            Swaraj Critique
          </NavLink>
          <NavLink to="vows" className={navLinkClassName}>
            Vows
          </NavLink>
          <NavLink to="bahishkrit_bharat" className={navLinkClassName}>
            Bahishkrit Bharat
          </NavLink>
          <NavLink to="twitter" className={navLinkClassName}>
            Twitter
          </NavLink>
        </nav>
      </div>
      <Outlet />
      {!user.isLoggedIn && <SignupLoginDialog />}
    </Container>
  );
}
