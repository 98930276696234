import React, { useState } from 'react';
import {
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from 'react-icons/bs';

import {
  extraContent,
  extraContentOrder1,
  extraContentOrder2,
  aboutUsInfo,
} from './AboutData';

const Banner = () => {
  const [readMore, setReadMore] = useState(false);

  return (
    <div className="about_container" id="top">
      <div className="wrapper">
        <div className="about-content">
          <h1 className="second_heading">Let&apos;s Read BabaSaheb</h1>
          <p className="aboutUS_info">
            {aboutUsInfo}
            <br />

            {readMore && (
              <div>
                <p className="extra-content">
                  {extraContent}
                  <br />
                  {extraContentOrder1}
                  <br />
                  {extraContentOrder2}
                  <br />
                </p>
              </div>
            )}

            <button
              className="read_more"
              onClick={() => setReadMore(!readMore)}
            >
              {readMore ? (
                <>
                  Read Less
                  <BsFillArrowUpCircleFill style={{ marginLeft: '10px' }} />
                </>
              ) : (
                <>
                  Read More
                  <BsFillArrowDownCircleFill style={{ marginLeft: '10px' }} />
                </>
              )}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
