export const userLogin = (userdata) => {
  return {
    type: 'LOGIN',
    payload: userdata,
  };
};

export const userLogout = () => {
  return {
    type: 'LOGOUT',
  };
};
