import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import '../../component/SignupDialog.css';

function EndOfBookPopup() {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Jai Bhim! You have reached the end of the book!</DialogTitle>
      <div className="SignupLoginDialogContainer">
        <Button color="info" onClick={handleClose}>
          Close
        </Button>
      </div>
    </Dialog>
  );
}

export default EndOfBookPopup;
