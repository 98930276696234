import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Sort = ({ label, options, defaultValue, onChange }) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
      <InputLabel>{label}</InputLabel>
      <Select value={defaultValue} label={label} onChange={onChange}>
        {Object.entries(options).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Sort;
