import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import { action as loginAction } from './pages/auth/Login';
import { action as signUpAction } from './pages/auth/Signup';

import AboutUS from './pages/AboutUS/AboutUS';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import AuthAction from './pages/auth/AuthAction';
import BookMarks from './pages/BookMarks';
import Vows from './pages/Vows/Vows';
import BahishkritBharat from './pages/BahishkritBharat';
import Twitter from './pages/Twitter';
import Books from './pages/Books/Books';
import BookView from './pages/BookView/BookView';
import Search from './pages/Search';
import BahishkritBharatArticle from './pages/BahishkritBharatArticle';
import ProfilePage from './pages/Profile/ProfilePage';
import SwarajCritiqueArticles from './pages/SwarajCritiqueArticles';
import SwarajCritique from './pages/SwarajCritique';

import HomeLayout from './layout/HomeLayout';
import { Layout } from './layout/Layout';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Books />} />
          <Route path="search" element={<Search />} />
          <Route path="bookmarks" element={<BookMarks />} />
          <Route path="vows" element={<Vows />} />
          <Route path="bahishkrit_bharat" element={<BahishkritBharat />} />
          <Route path="swaraj-critique" element={<SwarajCritiqueArticles />} />
          <Route path="twitter" element={<Twitter />}></Route>
        </Route>
        <Route path="about" element={<AboutUS />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="login" element={<Login />} action={loginAction} />
        <Route path="signup" element={<Signup />} action={signUpAction} />
        <Route path="auth/action" element={<AuthAction />} />
        <Route path="*" element={<BookView />} />
        {/* path="*" is for the error page, no? Also it should be outside of Routes parent wrapper */}
        <Route path="books">
          <Route
            path=":bookParent/:language/swaraj-critique/:date/:article"
            element={<SwarajCritique />}
          />
          <Route
            path=":bookParent/:language/bahishkrit-bharat/:date/:article"
            element={<BahishkritBharatArticle />}
          />
        </Route>
      </Route>
      <Route path="books">
        <Route
          path=":bookParent/:language/:bookName/:pageType/:pageNo"
          element={<BookView />}
        />
        <Route path=":bookParent/:language/:bookName" element={<BookView />} />
      </Route>
    </>
  )
);
