import React from 'react';
import People from './People';
import { peopleInfo } from './AboutData';

const Bio = () => {
  const items = peopleInfo.sort(() => Math.random() - 0.5);

  return (
    <div>
      <h1
        style={{
          textAlign: 'center',
          margin: '50px 0px 20px 0px',
          color: '#144272',
        }}
      >
        ✨ Meet Our Team ✨
      </h1>

      <div>
        {items.map((item) => (
          <People key={item.id} person={item} />
        ))}
      </div>
    </div>
  );
};

export default Bio;
