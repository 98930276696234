import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import parse from 'html-react-parser';
import './search.css';

import {
  getPageContext,
  initiate_interval_tree,
} from '../util/interval_tree.ts';
import { bookHistory, key_bookUrl } from '../util/constants';

export default function Search(props) {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  initiate_interval_tree();

  useEffect(() => {
    const search = async () => {
      const search_text = searchParams.get('q') || '';
      const stype = searchParams.get('searchType') || 'simple';
      let url = '';

      try {
        if (!search_text) return;

        if (search_text.length > 128) {
          alert('Try shorter text!');
          return;
        }

        if (stype === 'simple') {
          url = `https://lqpsx6t02d.execute-api.ap-south-1.amazonaws.com/default/baws-search?q=${search_text}`;
        } else if (stype === 'vector') {
          url = `https://0pe3xvyfpi.execute-api.ap-south-1.amazonaws.com/prod/search?page=1&size=25&vector_search=true&q=${search_text}`;
        } else {
          url = `https://0pe3xvyfpi.execute-api.ap-south-1.amazonaws.com/prod/search?page=1&size=25&filter_stop_words=true&q=${search_text}`;
        }

        const response = await fetch(url);

        if (!response.ok) {
          alert('Result Not Found');
          throw new Error('Network response was not ok');
        }

        const json = await response.json();

        if (json && json.results.length > 0) {
          setData(json.results);
        } else {
          alert('Result Not Found');
        }
      } catch (error) {
        console.error(error);
      }
    };

    search();
  }, [searchParams.get('q'), searchParams.get('searchType')]);

  const setBookContextUrl = (e, volName, pageNum) => {
    e.preventDefault();

    bookHistory.set(key_bookUrl, {
      shortUrl: e.target.href,
      longUrl: `https://assets.studyring.org/assets/books/baws/EN/${volName}.pdf#page=${pageNum}`,
    });

    navigate(`/books/baws/EN/${volName}/pdf/${pageNum}`);
  };

  return (
    <>
      {data.map(function (searchRow, idx) {
        const pageContext = getPageContext(searchRow);

        return (
          <Row key={searchRow.id.raw} className="mt-3">
            <Col>
              <Card>
                <Card.Body>
                  <div style={{ marginBottom: '1rem' }}>
                    <p>
                      {parse(searchRow.data.snippet.replace(/em>/g, 'strong>'))}
                    </p>
                    <a
                      href={`/books${searchRow.path.raw}${searchRow.container.raw}/pdf/${searchRow.actual_page.raw}`}
                      onClick={(e) =>
                        setBookContextUrl(
                          e,
                          searchRow.container.raw,
                          searchRow.actual_page.raw
                        )
                      }
                    >
                      {`${searchRow.container.raw} (Page No: ${searchRow.actual_page.raw})`}
                    </a>
                  </div>

                  <Breadcrumb>
                    {pageContext.map((context_str, i) => {
                      const { vol_name, page_start, label } =
                        JSON.parse(context_str);
                      return (
                        <Breadcrumb.Item
                          key={`${searchRow.id.raw}-${i}`}
                          href={`/books/baws/EN/${vol_name}/pdf/${page_start}`}
                          onClick={(e) =>
                            setBookContextUrl(e, vol_name, page_start)
                          }
                          style={{ fontSize: '0.75rem' }}
                        >
                          {label}
                        </Breadcrumb.Item>
                      );
                    })}
                  </Breadcrumb>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        );
      })}
    </>
  );
}
