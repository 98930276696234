import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import groupBy from 'lodash.groupby';

import Sort from './Sort';
import SortedCatalogue from './SortedCatalogue';
import PopularTopics from './PopularTopics';
import SignupLoginDialog from '../../component/SignupDialog';

import { catalogueDataFull } from './data';
import { useCurrentlyReading } from '../../hooks/useCurrentlyReading';
import { auth } from '../../config/firebase';

import './books.css';

const SORT_OPTIONS = {
  Default: 'Default',
  Volume: 'Volume',
  Year: 'Year',
};

const LANGUAGE_OPTIONS = {
  English: 'English',
  Hindi: 'Hindi',
  Marathi: 'Marathi',
};

const STATUS_OPTIONS = {
  allBooks: 'All Books',
  reading: 'Reading',
  notReading: 'Not Reading',
};

export default function Books() {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultFilterBy = searchParams.get('filterBy') || 'English';
  const defalutSortBy = searchParams.get('sortBy') || 'Default';
  const { currentlyReading, toggleBook } = useCurrentlyReading();
  const [isSignupPopupOpen, setIsSignupPopupOpen] = useState(false);
  const [filterByReading, setFilterByReading] = useState('allBooks');

  const handleFilter = (e) => {
    const value = e.target.value;
    const currentParams = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...currentParams, filterBy: value });
  };

  const handleSort = (e) => {
    const value = e.target.value;
    const currentParams = Object.fromEntries(searchParams.entries());
    setSearchParams({ ...currentParams, sortBy: value });
  };

  const handleReadingFilter = (e) => {
    setFilterByReading(e.target.value);
  };

  const handleToggle = (book) => {
    const user = auth.currentUser;
    if (!user) setIsSignupPopupOpen(true);
    toggleBook(book);
  };

  const groupByDefault = () => 'Books';

  const groupByVolume = (book) => `Volume ${book.Volume}`;

  const groupByYear = (book) => {
    if (book.Year >= 1910 && book.Year <= 1919) return '10s';
    if (book.Year >= 1920 && book.Year <= 1929) return '20s';
    if (book.Year >= 1930 && book.Year <= 1939) return '30s';
    if (book.Year >= 1940 && book.Year <= 1949) return '40s';
    if (book.Year >= 1950 && book.Year <= 1959) return '50s';
  };

  var langCode;

  switch (defaultFilterBy) {
    case 'Marathi':
      langCode = '/MR/';
      break;
    case 'Hindi':
      langCode = '/HI/';
      break;
    default:
      langCode = '/EN/';
      break;
  }

  let catalogueData = catalogueDataFull.filter((item) =>
    item.Path.includes(langCode)
  );

  catalogueData = catalogueData.map((book) => {
    const isReading = currentlyReading.some((e) => e.link === book.Path);
    return { ...book, isReading };
  });

  catalogueData =
    filterByReading === 'allBooks'
      ? catalogueData
      : catalogueData.filter((book) =>
          filterByReading === 'reading' ? book.isReading : !book.isReading
        );

  const groupedData =
    defalutSortBy === 'Default'
      ? groupBy(catalogueData, groupByDefault)
      : defalutSortBy === 'Year'
        ? groupBy(catalogueData, groupByYear)
        : groupBy(catalogueData, groupByVolume);

  const sortedData = Object.entries(groupedData).sort((a, b) =>
    parseFloat(a[0]) < parseFloat(b[0]) ? -1 : 1
  );

  return (
    <Row>
      <div className="filter-container">
        <Sort
          label="Language"
          options={LANGUAGE_OPTIONS}
          defaultValue={defaultFilterBy}
          onChange={handleFilter}
        />

        <Sort
          label="Sort"
          options={SORT_OPTIONS}
          defaultValue={defalutSortBy}
          onChange={handleSort}
        />

        <Sort
          label="Status"
          options={STATUS_OPTIONS}
          defaultValue={filterByReading}
          onChange={handleReadingFilter}
        />
      </div>

      <Col>
        <SortedCatalogue
          sortedData={sortedData}
          sortedBy={defalutSortBy}
          onToggle={handleToggle}
        />
        <PopularTopics />
      </Col>

      {isSignupPopupOpen && (
        <SignupLoginDialog
          showSignupPopup={isSignupPopupOpen}
          setIsSignupPopupOpen={setIsSignupPopupOpen}
        />
      )}
    </Row>
  );
}
