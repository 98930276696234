import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { Typography } from '@mui/material';

export default function Twitter() {
  return (
    <TwitterTimelineEmbed
      url="https://baws.in"
      screenName="baws_bot"
      sourceType="profile"
      placeholder={<Typography variant="h5">Loading.....</Typography>}
      options={{ width: '70%' }}
    />
  );
}
