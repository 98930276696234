import { React } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import vowsArr from './data.json';

const BOOK_URL = '/books/baws/EN/Volume_17_03/pdf/559';

const Vows = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const langs = vowsArr.map((v) => v.id);
  const currentLang = langs.find((l) => l === searchParams.get('lang')) || 'en';
  const currentVow = vowsArr.find((v) => v.id === currentLang);

  return (
    <div className="mb-3">
      <div className="d-flex" style={{ gap: '0.25rem' }}>
        {vowsArr.map(({ id, name }) => (
          <Button
            key={id}
            variant={currentLang == id ? 'success' : 'outline-success'}
            onClick={() => setSearchParams({ lang: id }, { replace: true })}
          >
            {name}
          </Button>
        ))}
      </div>

      <Card className="mt-5" id="vowsCard">
        <Card.Body>
          <Card.Title>{currentVow.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {currentVow.description}
          </Card.Subtitle>

          <Card.Link href={currentVow.url || BOOK_URL}>
            Direct book link
          </Card.Link>

          <Card.Text>
            <ListGroup variant="flush">
              {currentVow.vows.map((vow, i) => (
                <ListGroup.Item key={i}>{vow}</ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Vows;
