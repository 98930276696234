import Grid from '@mui/material/Grid';
import { CardContent, Typography } from '@mui/material';

import BookCoverPage from './BookCoverPage';
import './SortedCatalogue.css';

const SortedCatalogue = ({ sortedData, onToggle }) => {
  if (sortedData.length === 0) {
    return (
      <div>
        <CardContent>
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            Nothing to show here.
          </Typography>
        </CardContent>
      </div>
    );
  }

  return (
    <div>
      {sortedData.map(([key, value]) => {
        return (
          <div key={key} className="SortedCatalogue">
            <h2 className="SortedCatalogue__title">{key}</h2>
            <Grid container spacing={2}>
              {value.map((catalog) => {
                return (
                  <Grid item lg={2} md={3} sm={4} xs={6} key={catalog.Path}>
                    <BookCoverPage
                      link={catalog.Path}
                      imageSrc={
                        'https://baws.in/assets/catalog/' + catalog.Filename
                      }
                      bookLabel={catalog.Label}
                      endPage={catalog.EndPage}
                      onToggle={onToggle}
                      isReading={catalog.isReading}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default SortedCatalogue;
