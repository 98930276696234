import './Footer.css';

const Footer = () => {
  return (
    <div className="Footer">
      <a target="_blank" href="https://ambedkarinternationalcenter.org">
        Ambedkar International Center, USA
      </a>
      <a href="/about"> About Us </a>
    </div>
  );
};

export default Footer;
